import React from "react";

import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import Link from "../Link";
import { Text } from "../../style/Text";
import Icon from "../../style/Icon";
import { Heading } from "../../style/Heading";
import { URLS } from "../../const/const";

const careerSitesData = [
  {
    title: "Komplexní finanční poradenství",
    text:
      "Známe podmínky bank a&nbsp;pojišťoven, dokážeme je porovnat a&nbsp;připravíme vám nabídku nejzajímavějších pojistek, hypoték nebo investic, abyste se&nbsp;mohli dobře rozhodnout.",
    cta: null,
    active: true,
  },
  {
    title: "KAPITOL Gold",
    text:
      "Nabízíme klientům možnost investovat volné finanční prostředky i&nbsp;do&nbsp;cenných kovů, jako je zlato, stříbro a&nbsp;platina. Máte zájem o&nbsp;jednorázový nákup nebo o&nbsp;pravidelné spoření?",
    cta: {
      slug: "https://www.kapitolgold.cz/",
      name: "www.kapitolgold.cz",
    },
    active: false,
  },
  {
    title: "KAPITOL Reality",
    text:
      "Prodáváte byt nebo dům? Hledáte nájemníky? Rádi vám pomůžeme ve&nbsp;všech segmentech realitního trhu, včetně právního a&nbsp;finančního servisu.",
    cta: {
      slug: "https://www.kapitolreality.cz",
      name: "www.kapitolreality.cz",
    },
    active: false,
  },
  {
    title: "KAPITOL Autopojištění",
    text:
      "Pro prodejce nových a&nbsp;ojetých vozidel nabízíme komplexní řešení v&nbsp;oblasti autopojištění pro&nbsp;jejich zákazníky.",
    cta: {
      slug: URLS.carInsurance,
      name: "www.kapitolautopojisteni.cz",
    },
    active: false,
  },
];

const CareerSites = ({ type }) => {
  if (type === "hp") {
    return (
      <Wrapper bg={"white.100"} bt={3} pb={3}>
        <Container px={["15px", null, "31px", null, "15px"]}>
          <Flex flexWrap="wrap" justifyContent="space-between">
            {careerSitesData.map((item, index) => (
              <Box
                key={index}
                position="relative"
                mb={[3, null, null, 0]}
                p={3}
                pb={[item.cta ? 55 : 3]}
                background={
                  item.active
                    ? "linear-gradient(180deg, #006D7B 0%, #00B8A6 100%);"
                    : null
                }
                bg={"panelSecondary"}
                borderRadius={1}
                width={["calc(50% - 10px)", null, null, "calc(25% - 22px)"]}
              >
                <Heading
                  as={"h4"}
                  fontSize={[18, null, 20]}
                  fontWeight="semiBold"
                  lineHeight={1}
                  mb="0.5em"
                  color={item.active ? "white.100" : null}
                >
                  {item.title}
                </Heading>

                <Text
                  display={["none", null, "block"]}
                  color={item.active ? "white.100" : null}
                  lineHeight={2}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />

                {item.cta && (
                  <Box position="absolute" bottom={3} right={3} left={3}>
                    <Link
                      mt={4}
                      variant="smallBorderTop"
                      href={item.cta.slug}
                      target="_blank"
                    >
                      <Box as={"span"} display={["none", null, null, "block"]}>
                        {item.cta.name}
                      </Box>
                      <Box as={"span"} display={["block", null, null, "none"]}>
                        Více
                      </Box>
                      <Icon
                        mb={"-1px"}
                        ml={1}
                        icon="icon-next"
                        size={10}
                        color="cta"
                      />
                    </Link>
                  </Box>
                )}
              </Box>
            ))}
          </Flex>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper bg={"panelPrimary"} bt={3} pb={3}>
      <Container>
        <Flex flexWrap="wrap" justifyContent="space-between">
          {careerSitesData.map((item, index) => (
            <Box
              key={index}
              position="relative"
              mb={[3, null, null, 0]}
              p={3}
              pb={[item.cta ? 55 : 3, 55]}
              bg={"white.100"}
              borderRadius={1}
              width={["100%", "calc(50% - 10px)", null, "calc(25% - 22px)"]}
            >
              <Heading
                as={"h4"}
                fontSize={[18, null, 20]}
                fontWeight="semiBold"
                lineHeight={1}
                mb="0.5em"
              >
                {item.title}
              </Heading>

              <Text
                lineHeight={2}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />

              {item.cta && (
                <Box position="absolute" bottom={3} right={3} left={3}>
                  <Link
                    mt={4}
                    variant="smallBorderTop"
                    href={item.cta.slug}
                    target="_blank"
                  >
                    {item.cta.name}
                    <Icon
                      mb={"-1px"}
                      ml={1}
                      icon="icon-next"
                      size={10}
                      color="cta"
                    />
                  </Link>
                </Box>
              )}
            </Box>
          ))}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default CareerSites;
