import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { List } from "../../style/List";
import { StaticImage } from "gatsby-plugin-image";

const BehindTheScene = () => (
  <Wrapper bg="panelSecondary" pt={4}>
    <Container>
      <Flex flexDirection={["column", null, null, null, "row"]} position={"relative"}>
        <Box
          backgroundSize={"cover"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
          position={["static", null, null, "absolute"]}
          maxWidth={"100%"}
          right={0}
          width={["100%", null, null, "400px"]}
          height={[460, null, null, null, 432]}
          borderRadius={1}
          mb={[0, 3, 4, 6]}
        >
          <StaticImage src={"../../assets/images/aboutUs/silne-zazemi.jpg"} style={{ width: "100%", height: "100%", borderRadius: "6px" }} opacity="0" />
        </Box>
        <Box mt={[28, null, 0]} width={["100%", null, null, "60%"]}>
          <Flex alignItems="center">
            <Box>
              <Heading as="h2" pt={[3, null, 24]} mb={[3, null, null, 4]} fontWeight="light" letterSpacing="caps" fontSize={[4, null, null, 5]} lineHeight={0}>
                MŮŽEME SE OPŘÍT O&nbsp;SILNÉ ZÁZEMÍ
              </Heading>
              <Text fontSize={3} mb={24} lineHeight={1} color="headlines">
                Společnost KAPITOL patří do&nbsp;významné finanční skupiny Vienna Insurance Group (VIG), do&nbsp;které patří také nejvýznamnější společnosti
                rakouského a&nbsp;českého trhu – Wiener Städtische a&nbsp;Kooperativa.
              </Text>
            </Box>
            <Box width={1} maxWidth={145} ml={4} display={["none", null, "block"]}>
              <StaticImage display={"block"} minWidth={145} src={"../../assets/images/about-vig-logo.png"} />
            </Box>
          </Flex>

          <List variant={"circle"} mb={[5, null, null, 102]} ml={3}>
            <li>Největší pojišťovací skupina ve&nbsp;střední a&nbsp;východní Evropě.</li>
            <li>Vznikla z&nbsp;iniciativy rakouské pojišťovny Wiener Städtische.</li>
            <li>Celkem (včetně Rakouska) sdružuje 50 společností ve&nbsp;24&nbsp;zemích.</li>
            <li>
              Finanční síla Vienna Insurace Group byla ohodnocena mezinárodní ratingovou agenturou Standard and Poor´s vynikajícím stupněm A+ se&nbsp;stabilním
              výhledem.
            </li>
            <li>Akcie VIG jsou obchodovány na&nbsp;vídeňské a&nbsp;pražské burze, kde patří ke&nbsp;spolehlivým titulům.</li>
          </List>
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default BehindTheScene;
