import React from "react";
import { Box, Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";

const BoardPerson = ({ image, name, position, width }) => (
  <Flex mb={image ? 4 : 52} width={width && width} pr={[0, null, 3]}>
    {image && (
      <Box mr={3} flex={"1 1 44%"}>
        {image}
      </Box>
    )}
    <Box flex={"1 1 40%"}>
      <Heading as="h3" fontSize={3} fontWeight="regular">
        {name}
      </Heading>
      <Text fontSize={[1, null, null, 2]} fontWeight="regular">
        {position}
      </Text>
    </Box>
  </Flex>
);

export default BoardPerson;
