import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import CareerBlock from "../../components/CareerBlock";
import BehindTheScene from "../../components/BehindTheScene";
import Board from "../../components/Board";
import AboutTopBlock from "../../components/AboutTopBlock";
import CareerSites from "../../components/CareerSites";
import SEO from "../../components/seo";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";

import { URLS } from "../../const/const";
import { StaticImage } from "gatsby-plugin-image";

const AboutUs = ({
  data: {
    wp: {
      page: {
        title,
        featuredImage,
        uri,
        seo: { metaDesc },
      },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: title,
      link: uri,
    },
  ];

  const bannerCareer = {
    fieldGroupName: "banner",
    title: "MÁTE ZÁJEM S&nbsp;NÁMI SPOLUPRACOVAT?",
    cta: {
      target: "",
      title: "Kariéra",
      url: URLS.career,
    },
    content:
      "<p>Díky stabilitě zázemí, letitým zkušenostem, rozsáhlým investicím do&nbsp;modernizaci systémů a&nbsp;především díky půl milionu spokojených klientů máme co nabídnout začínajícím poradcům i&nbsp;zkušeným obchodníkům. Přesvědčte se sami.</p>",
  };

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[3, null, 5]}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              O&nbsp;nás
            </Heading>
          </Flex>
        </Container>
      </Wrapper>

      <AboutTopBlock image={featuredImage} />

      <CareerSites />

      <Board />

      <BehindTheScene />

      <CareerBlock
        background={"dark"}
        content={bannerCareer}
        image={<StaticImage style={{ borderRadius: "6px" }} src={"../../assets/images/kariera-blok.jpeg"} alt={"Kariéra"} />}
      />
    </Layout>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query ABOUT_PAGE_QUERY {
    wp {
      page(id: "o-nas", idType: URI) {
        title
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
