import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { board, supervisors } from "../../const/const";
import BoardPerson from "./BoardPerson";

const Board = () => (
  <Wrapper bg="panelPrimary">
    <Container pb={[2, null, null, 5]} pt={4} flexDirection="column">
      <Heading as="h2" fontWeight="light" fontSize={[4, null, 5]}>
        MANAGEMENT KAPITOLU
      </Heading>
      <Flex flexDirection={["column", null, "column", "row"]}>
        <Box
          width={[1, null, null, 1 / 2]}
          minWidth={["auto", null, null, 550]}
        >
          <Box mr={[0, null, 3]}>
            <Heading
              as="h3"
              mb={2}
              fontSize={[18, null, 3]}
              fontWeight="semiBold"
            >
              PŘEDSTAVENSTVO
            </Heading>
            <Flex flexDirection="column">
              {board.map((person, index) => (
                <BoardPerson {...person} key={index} />
              ))}
            </Flex>
          </Box>
        </Box>

        <Box
          width={[1, null, null, 1 / 2]}
          borderLeft={["none", null, null, "1px solid #CBD0D2"]}
          borderTop={["1px solid #CBD0D2", null, null, "none"]}
          pl={[0, null, null, 3]}
          pt={[4, null, null, 0]}
        >
          <Heading
            as="h3"
            mb={2}
            mt={[3, null, 0]}
            fontSize={[18, null, 3]}
            fontWeight="semiBold"
          >
            DOZORČÍ RADA
          </Heading>
          <Flex flexWrap="wrap">
            {supervisors.map((person, index) => (
              <BoardPerson
                width={[1, null, 1 / 2, 1 / 2]}
                {...person}
                key={index}
              />
            ))}
          </Flex>
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default Board;
