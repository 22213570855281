import styled from "styled-components";
import { variant } from "styled-system";
import propTypes from "@styled-system/prop-types";
import Box from "../Grid/Box";

const List = styled(Box)`
  list-style: disc;
  li {
    margin-bottom: 1em;
  }
  ${props =>
    variant({
      variants: {
        check: {
          listStyle: "none",
          li: {
            mb: "1em",
            position: "relative",
            paddingLeft: "30px",
          },
          svg: {
            position: "absolute",
            top: "5px",
            left: "0",
          },
        },
        circle: {
          marginLeft: "2em",
          listStyle: "none",
          li: {
            marginBottom: 2,
            fontSize: 16,
            lineHeight: "19px",
          },
          "& > li::before": {
            backgroundColor: "black",
            content: "'\\2022'",
            color: "headlines",
            display: "inline-block",
            width: "2em",
            marginLeft: "-2em",
          },
        },
      },
    })}
`;

List.displayName = "List";

List.defaultProps = {
  as: "ul",
  display: "block",
  fontSize: 2,
  fontWeight: "normal",
  color: "bodyText",
};

List.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { List };
