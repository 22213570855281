import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";

const AboutTopBlock = ({ image }) => (
  <Wrapper bg="panelPrimary" pt={[0, null, 4]} pb={[0, null, 4]}>
    <Container>
      <Flex width={1} flexDirection={["column-reverse", null, "row"]}>
        <Flex maxWidth="590px" flexGrow={1} mr={[0, null, "20px"]} flexDirection={["column", null, null, "row"]}>
          <Box flexGrow={1} width={1} mr={[0, null, 3]} mb={[4, null, 0]} pr={[0, null, 3]}>
            <Text fontSize={3} mb={3} lineHeight={1} color="headlines" letterSpacing="caps">
              Jsme česká společnost s&nbsp;bohatou historií od roku 1995 a&nbsp;patříme do&nbsp;významné finanční skupiny Vienna Insurance Group (VIG).
            </Text>
            <Text mb={3} lineHeight={2}>
              Během své existence jsme si vybudovali široké zázemí a&nbsp;díky zkušenostem i&nbsp;kvalitním partnerům patříme mezi hlavní představitele
              na&nbsp;trhu finančního poradenství. Naším cílem je spokojenost a&nbsp;důvěra našich klientů, což jsou základní předpoklady k&nbsp;dlouhodobé
              a&nbsp;vzájemně prospěšné spolupráci.
            </Text>
            <Text lineHeight={2}>
              Pomáháme vám dělat dobrá rozhodnutí ve&nbsp;všech oblastech, která máme rozdělena na&nbsp;jednotlivé divize poradenství a&nbsp;služeb:
            </Text>
          </Box>
        </Flex>

        <Box flexGrow={1} mb={[43, null, 0]}>
          <Image
            maxWidth={["calc(100% + 30px)", null, "590px"]}
            mt={{ xl: "-30%" }}
            boxShadow="one"
            width="calc(100% + 30px)"
            mx={[-15, null, 0]}
            borderRadius={["0px", null, 1]}
            display="block"
            src={image?.node?.sourceUrl ?? "../../assets/images/placeholder/test-image-10.jpg"}
          />
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default AboutTopBlock;
